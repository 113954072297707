<template>
  <div class="gameBox">
    <div class="box-top">
      <img src="https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1618914708.png" alt="">
    </div>
    <div class="box-pay-two">{{ config_info.title }}</div>
    <div class="changes">
      <span>您有 {{number_of_draws}} 次抽奖机会</span>
    </div>
    <div v-if="type_status" class="start-two" @click.once="move">
      <img src="@static/vip/prizeBtnIng.png" alt />
    </div>
    <div v-else class="start-two" @click="move">
      <img src="@static/vip/prizeBtnIng.png" alt />
    </div>
    <ul class="bg-con">
      <li v-for="(item,i) in list" :key="i" :class="['item' + (i+1), {'active': index == i}]">
        <div class="img1">
          <span v-if="item.type == 0" class="iconfont iconjifen3"></span>
          <span v-else-if="item.type == 1" class="iconfont iconxianjin"></span>
          <span v-else-if="item.type == 2" class="iconfont iconyouhuijuan"></span>
          <img v-else-if="item.type == 3" :src="item.cover_url" alt />
          <img v-else src="@static/vip/weixiao.png" alt />
        </div>
        <p v-if="item.item_name">{{item.item_name}}</p>
        <p v-else>{{item.level}}</p>
      </li>
      <div class="loading" v-if="loadingStatus">
        <Loading size="44px" color="#1989fa">加载中...</Loading>
      </div>
    </ul>
    <div class="my-list" v-if="list_status">
      <div class="my-list-title">
        <span>中奖记录</span>
        <router-link to="/winningRecord">我的奖品 ></router-link>
      </div>
      <div class="list-item" v-for="(item, idx) in my_list" :key="idx">
        <span>{{item.user.mobile}}</span>
        <span v-if="item.product.type == 0">积分{{item.product.amount}}</span>
        <span v-if="item.product.type == 1">余额{{item.product.amount}}元</span>
        <template v-if="item.product.type == 2">
          <span v-for="(cou, idx) in item.product.coupon" :key="idx">{{cou.title}}</span>
        </template>
        <span v-if="item.product.type == 3">物品{{item.product.item_name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Setting from "@/api/setting";
import { Loading } from "vant";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      list: [], //奖品列表
      index: -1, // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
      count: 8, // 总共有多少个位置
      times: 0, // 转动跑格子次数,
      cycle: 2, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      speed: 200, // 初始转动速度
      lampShow: false, //开始抽奖，灯光闪烁
      timer: 0, // 转动定时器
      lamp: 0, // 灯光定时器
      prize: 0, // 中奖位置，接口返回
      number_of_draws: 1, //限制每天抽奖次数，接口返回
      my_list: [],
      prize_data: {
        //中奖信息
        id: Number, //奖品ID
        name: "", //奖品名称
        number: Number, //奖品数量
        image: "", //奖品图片
        type: Number // 奖品类型
      },
      type_status: false,
      config_info: {},
      list_status: Boolean,
      loadingStatus: false
    };
  },
  mounted() {
    if (this.$route.query.entry_type == 1) {
      this.type_status = false;
    } else {
      this.type_status = true;
    }
    // this.list = init.data.prizeInfo;
    this.getConfig();
    this.getMylist();
  },
  methods: {
    // 获取获奖列表
    async getMylist() {
      try {
        const res = await Setting.getMyward(1, 2);
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            this.list_status = true;
            res.data.result.map(item => {
              item.user.mobile = item.user.mobile.substr(0,3) +"****" + item.user.mobile.substr(7)
            })
            this.my_list = res.data.result;
          } else {
            this.list_status = false;
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getConfig() {
      try {
        const res = await Setting.roundConfig();
        if (res.code == 200) {
          this.config_info = res.wheel;
          this.number_of_draws = res.wheel.per_chance;
          this.list = res.data;
        }
      } catch (error) {
        console.log(error)
      }
    },
    //点击开始抽奖
    move() {
      this.loadingStatus = true;
      if (this.number_of_draws == 0) {
        this.$toast("今日抽奖次数已用完,明天再来吧");
        this.loadingStatus = false;
      } else if (this.times != 0) {
        this.$toast("正在抽奖中，请勿重复点击");
        this.loadingStatus = false;
      } else {
        Setting.getWinningInfo(this.config_info.id).then(res => {
          if (res.code == 200) {
            if (res.data.key || res.data.key == 0) {
              this.number_of_draws--; //抽奖开始，次数-1
              this.speed = 10; //每次抽奖速度初始化为200
              this.prize_data = res.data; //已经拿到中奖信息，页面展示，等抽奖结束后，将弹窗弹出
              this.prize = res.data.key; //中奖位置赋值，跑马灯最终停留位置，这里实际位置需要-1
              this.startRoll(); //执行抽奖
              this.lamp = setInterval(() => {
                //灯光闪烁开启
                this.lampShow = !this.lampShow
              }, 10);
            }
          } else {
            this.$toast(res.msg)
          }
          this.loadingStatus = false;

        })
      }
    },
    // 开始转动
    startRoll() {
      this.times += 1; // 转动次数
      this.oneRoll(); // 转动过程调用的每一次转动方法，这里是第一次调用初始化
      this.usePrize();
    },

    // 每一次转动
    oneRoll() {
      let index = this.index; // 当前转动到哪个位置
      const count = 8; // 总共有多少个位置
      index += 1;
      if (index > count - 1) {
        index = 0;
      } 
      this.index = index;
    },
    usePrize() {
      // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
      if (this.times > this.cycle + 10 && this.prize === this.index) {
        clearTimeout(this.timer); // 清除转动定时器
        clearTimeout(this.lamp); // 清除灯光定时器
        this.lampShow = false; // 白色灯隐藏
        this.times = 0; // 转动跑格子次数初始化为0，可以开始下次抽奖
        if (this.prize_data.id == 0 || this.prize_data.level == "谢谢参与") {
          this.$dialog({ message: '感谢您的参与'} );
        } else {
          var msg = "";
          switch (this.prize_data.type) {
            case 0:
              msg = `恭喜你获得${this.prize_data.amount}积分`
              break;
            case 1:
              msg = `恭喜你获得${this.prize_data.amount}余额`
              break;
            case 2:
              msg = `恭喜你获得优惠券`
              break;
            case 3:
              msg = `恭喜你获得${this.prize_data.item_name}`
              break;
            default:
              break;
          }
          this.$dialog.confirm({  
            title: "中奖啦", message: msg, cancelButtonText: "返回" 
          }).then(() => {
            // on confirm
          })
          .catch(() => {
            this.$router.push("/");
          });
        }
      } else {
        if (this.times < this.cycle - 20) {
          this.speed -= 10; // 加快转动速度
        } else {
          this.speed += 10; // 抽奖即将结束，放慢转动速度
        }
        this.timer = setTimeout(this.startRoll, this.speed); //开始转动
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/*
 整体布局采用定位实现
 gameBox:父盒子，最外层背景图
 bg1:灰色灯
 bg2:点击开始按钮后，白色灯出现，同时要每个500s同bg1做切换隐藏显示
 start：按钮样式
 item1-8:通过定位方式将dom元素顺时针排列
 active：点击开始开妞后，从1位置开始高亮，类似跑马灯
*/
.gameBox {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background: url("https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1612427541.jpeg") no-repeat;
  background-size: cover;
  padding-bottom: 10px;
  .changes {
    width: auto;
    border-radius: 20px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 4px 80px;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba(227, 161, 0, 1);
  }
  .box-top {
    width: 100%;
    height: 190px;
    font-size: 18px;
    text-align: right;
    margin: 20px auto 0;
  }
  .box-top img {
     width: 100%;
     height: 100%;
   }
  .my-list {
    width: 90%;
    max-height: 190px;
    padding: 10px 20px;
    margin: 20px auto;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fecb89;
    .my-list-title {
      width: 100%;
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      letter-spacing: 2px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;
      a {
        color: rgb(216, 95, 95);
        font-size: 15px;
      }
    }
    .list-item {
      width: 100%;
      display: flex;
      height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #3f3f44;
      justify-content: space-between;
      align-items: center;
    }
  }
  .bg-con {
    width: 100%;
    height: 325px;
    margin-left: 40px;
    margin-top: 20px;
    position: relative;
    background: url("../../../../public/static/images/vip/bg_turn.png")
      no-repeat;
    padding-bottom: 0;
    background-size: 330px 325px;
    .iconjifen3 {
      color: #ffa931;
      font-size: 34px;
    }
    .iconxianjin {
      color: #ff4301;
      font-size: 34px;
    }
    .iconyouhuijuan {
      color: #e7305b;
      font-size: 34px;
    }
  }
  .box-pay,
  .box-pay-two {
    width: 94%;
    height: 50px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    letter-spacing: 4px;
    font-family: serif;
    justify-content: space-between;
    border-bottom: 1px dashed #dfdfdf;
  }
  .box-pay-two {
    width: 100%;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconweixin1 {
    font-size: 32px !important;
    color: #09bb07 !important;
  }
  .iconzhifubao {
    color: #108ee9 !important;
    font-size: 32px !important;
  }
  .box-pay span:nth-child(1) {
    font-size: 20px;
    color: #363636;
    margin-top: 6px;
  }
  .box-pay span:nth-child(2) {
    font-size: 16px;
    color: #363636;
    margin-top: 6px;
  }
  .box-pay span:nth-child(2)::before {
    content: "\A5";
    font-size: 12px;
    font-weight: 560;
    color: #fff !important;
    margin-top: 6px;
  }
  .box-pay span:nth-child(3) {
    font-size: 12px;
    color: #fff !important;
    margin-top: 6px;
  }
  .dis-money {
    color: #fff !important;
    font-size: 14px !important;
  }
  .dis-money::before {
    content: "\A5";
    font-size: 10px;
    font-weight: 560;
    color: #fff !important;
    margin-top: 6px;
  }
  .box-pay-left {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .box-pay-left span {
    color: #fff !important;
  }
  .start,
  .start-two {
    position: absolute;
    width: 86px;
    height: 86px;
    top: 570px;
    margin-right: 5px;
    background-size: 86px 86px;
    z-index: 666;
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .start-two {
    top: 451px;
  }
  ul {
    li {
      position: absolute;
      width: 86px;
      height: 86px;
      background: rgba(255, 255, 255, 1);
      border: 2px solid rgba(227, 161, 0, 1);
      border-radius: 8px;
      .img1 {
        margin: 15px auto 3px;
        width: 35px;
        height: 35px;
        img {
          width: 100%;
          height: auto;
        }
      }
      p {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
      }
    }
    .item1 {
      left: 25px;
      top: 25px;
    }
    .item2 {
      left: 120px;
      top: 25px;
    }
    .item3 {
      left: 215px;
      top: 25px;
    }
    .item4 {
      left: 215px;
      top: 120px;
    }
    .item5 {
      left: 215px;
      top: 215px;
    }
    .item6 {
      left: 120px;
      top: 215px;
    }
    .item7 {
      left: 25px;
      top: 215px;
    }
    .item8 {
      left: 25px;
      top: 120px;
    }
    .active {
      background: #fff2b1;
    }
    .loading{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      z-index: 5000;

    }
  }
  
}
</style>